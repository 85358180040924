import { Card } from './Card';

export function DesignPreview() {
  const card1 = {
    title: 'First Card Title',
    description: 'This is the work description for the first card',
    img: 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.dreamhost.com%2Fpro-services%2Fdesign%2F&psig=AOvVaw2X_kYjFjLpKpzJx8-xwCbw&ust=1704822132029000&source=images&cd=vfe&opi=89978449&ved=0CBEQjRxqFwoTCPi4w76rzoMDFQAAAAAdAAAAABAE',
  };

  return (
    <div
      className="flex flex-col items-center justify-start px-16 py-12 sm:px-24 sm:py-20"
      id="portfolio"
    >
      <p className="self-end pb-7">Selected Works</p>

      <div className="grid gap-4 sm:grid-cols-2">
        <Card title="Client" description="Short description text" img={''} />
        <Card title="Client" description="Short description text" img={''} />
        <Card title="Client" description="Short description text" img={''} />
        <Card title="Client" description="Short description text" img={''} />
      </div>
    </div>
  );
}
