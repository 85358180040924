import redFlowers from '../assets/red-flowers.svg';

type TCard = {
  title: string;
  description: string;
  img: string;
};

export const Card = (card: TCard) => {
  return (
    <div className="translate translate-y-2 bg-purple-800 hover:drop-shadow-2xl">
      <div className="px-5 py-6">
        <div className="text-xl text-white">{card.title}</div>
        <p className="text-base text-white">{card.description}</p>
      </div>
      <img className="" src={redFlowers} alt="Sunset in the mountains" />
    </div>
  );
};
