import redFlowers from '../assets/red-flowers.svg';
import orangeFlowers from '../assets/orange-flowers.svg';
import greenFlowers from '../assets/green-flowers.svg';
import aCollective from '../assets/acollective.png';

export function Banner() {
  return (
    <div className="items-center justify-center">
      <img src={aCollective} alt="ACOLLECTIVE" className="px-16 sm:px-24"></img>
      <div className="flex items-end justify-center">
        <img src={redFlowers} className="flex-auto" alt="img1"></img>
        <img src={orangeFlowers} className="flex-auto" alt="img2"></img>
        <img src={greenFlowers} className="flex-auto" alt="img3"></img>
      </div>
    </div>
  );
}
