import { useState } from 'react';
import asLogo from '../assets/as-logo.svg';
import { Link } from 'react-scroll';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid';

export function NavBar() {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <nav
      className="bg-light-background sm-pb-10 top-0 px-16 pb-6 pt-12 sm:px-24 sm:pt-20"
      id="top"
    >
      <div className="flex flex-wrap items-center justify-between">
        <Link to="home" spy={true} smooth={true} offset={50} duration={500}>
          <img src={asLogo} alt="LOGO" className="hover:fill-gray-400" />
        </Link>
        <div className="mobile-menu block md:hidden">
          {!navbarOpen ? (
            <button
              onClick={() => setNavbarOpen(true)}
              className="border-black-500 text-black-500 flex items-center rounded border px-3 py-2 hover:border-gray-400 hover:text-gray-400"
            >
              <Bars3Icon className="h-5 w-5" />
            </button>
          ) : (
            <button
              onClick={() => setNavbarOpen(false)}
              className="border-black-500 text-black-500 flex items-center rounded border px-3 py-2 hover:border-gray-400 hover:text-gray-400"
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          )}
        </div>
        <div className="hidden flex-row space-x-20 md:block">
          <Link
            to="portfolio"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
            className="cursor-auto text-center text-[20px] font-normal text-black hover:text-gray-400 active:text-gray-400"
          >
            Portfolio
          </Link>
          <button className="text-center text-[20px] font-normal text-black hover:text-gray-400">
            Artwork
          </button>
          <button className="text-center text-[20px] font-normal text-black hover:text-gray-400">
            About
          </button>
        </div>
      </div>
      {navbarOpen ? (
        <div className="flex flex-col justify-center space-y-10 pt-10">
          <Link
            to="portfolio"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
            className="text-center text-[18px] font-normal text-black hover:text-gray-400"
          >
            Portfolio
          </Link>
          <button className="text-center text-[18px] font-normal text-black hover:text-gray-400">
            Artwork
          </button>
          <button className="text-center text-[18px] font-normal text-black hover:text-gray-400">
            About
          </button>
        </div>
      ) : null}
    </nav>
  );
}
