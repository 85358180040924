export function Contact() {
  return (
    <div className="flex flex-col items-center pb-10">
      <div className="pb-10">
        <p className="text-2xl font-bold">Let's Talk</p>
      </div>
      <div className="flex flex-row justify-center gap-6">
        <input
          type="text"
          placeholder="Let's talk"
          className="lg:w-autp border-2 border-gray-400 px-2 focus:border-black focus:outline-none focus:ring-0 sm:w-96 md:w-[36rem]"
        ></input>
        <button
          className="bg-purple-800 px-4
      py-2 text-white"
        >
          Send
        </button>
      </div>
    </div>
  );
}
